export const entity = {
  namespaced: true,
  state: {
    entity: {},
    entities: [],
  },
  mutations: {
    addEntity(state, entity) {
      state.entities.unshift(entity);
    },
    setEntity(state, entity) {
      state.entity = entity;
    },
    setEntities(state, entities) {
      state.entities = entities;
    },
    deleteEntity(state, id) {
      const index = state.entities.findIndex((entity) => entity.id == id);
      state.entities.splice(index, 1);
    },
    updateEntity(state, entity) {
      const index = state.entities.findIndex(
        (entity) => entity.id == entity.id
      );
      state.entities.splice(index, 1, entity);
    },
  },
  actions: {
    addEntity({ commit }, entity) {
      commit("addEntity", entity);
    },
    setEntity({ commit }, entity) {
      commit("setEntity", entity);
    },
    setEntities({ commit }, entities) {
      commit("setEntities", entities);
    },
    deleteEntity({ commit }, id) {
      commit("deleteEntity", id);
    },
    updateEntity({ commit }, entity) {
      commit("updateEntity", entity);
    },
  },
  getters: {
    getCurrentEntity: (state) => {
      return state.entity;
    },
    getEntities: (state) => {
      return state.entities;
    },
  },
};
