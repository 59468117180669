export const organization = {
  namespaced: true,
  state: {
    organizations: [],
  },
  mutations: {
    setOrganization(state, organization) {
      state.organizations.unshift(organization);
    },
    setOrganizations(state, organizations) {
      state.organizations = organizations;
    },
    removeOrganization(state, id) {
      const index = state.organizations.findIndex((r) => r.id == id);
      state.organizations.splice(index, 1);
    },
    updateOrganization(state, organization) {
      const index = state.organizations.findIndex(
        (r) => r.id == organization.id
      );
      state.organizations.splice(index, 1, organization);
    },
  },
  actions: {
    setOrganizations({ commit }, organizations) {
      commit("setOrganizations", organizations);
    },
    setOrganization({ commit }, organization) {
      commit("setOrganization", organization);
    },
    removeOrganization({ commit }, id) {
      commit("removeOrganization", id);
    },
    updateOrganization({ commit }, organization) {
      commit("updateOrganization", organization);
    },
  },
  getters: {
    getOrganizations: (state) => {
      return state.organizations;
    },
  },
};
