import gql from "graphql-tag";

export default gql`
  {
    id
    email
    entity {
      id
      name
      modules {
        module {
          id
          name
        }
      }
      status
      category
    }
    name
    office
    photo
    type
    telephone
    role {
      id
      name
      permissions {
        id
        name
      }
    }
  }
`;
